<!-- 公共头部菜单 -->
<template>
  <div class="headerWrap">
    <div class="header w100" :class="{ headerScr: isScroll }">
      <div class="logoWrap"><img :src="logoImg" alt=""></div>
      <div class="age"><img :src="ageImg" alt=""></div>
      <div class="navWrap">
        <span class="navLi" :class="{ active: index == navActive }" v-for="(item, index) in navList" :key="index"
          @click="navFn(item, index)">{{ item.name }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { publicUrl } from '@/common/publicUrl.js'
import Cookies from 'js-cookie'
export default {
  name: 'App',
  components: {
  },
  data() {
    return {
      isScroll: false,
      navActive: 0,
      logoImg: require('@/assets/imgs/head/logo.png'),
      ageImg: require('@/assets/imgs/head/age.png'),
      navList: [
        { name: '官网首页', url: '/' },
        { name: '快速注册', url: 'Register' },
        { name: '下载中心', url: 'DownLoad' },
        { name: '商城专区', url: publicUrl.shopHomeUrl },
        { name: '新闻中心', url: 'News' },
        { name: '充值中心', url: 'Recharge' },
        { name: '游戏排行', url: 'Rank' },
      ],
    }
  },
  created() {
    window.addEventListener('scroll', this.winScroll)
    this.navActive = this.navList.findIndex((item) => item.url == this.$route.name);
  },
  methods: {
    winScroll() {
      let scrollTop = document.documentElement.scrollTop
      if (scrollTop > 0) {
        this.isScroll = true
      } else {
        this.isScroll = false
      }
    },
    navFn(item, ind) {   // 点击菜单
      console.log(item, ind);
      const tiao = localStorage.getItem('17173') ? localStorage.getItem('17173') : 0;
      const tiao2 = localStorage.getItem('9i173') ? localStorage.getItem('9i173') : 0;
      if ((tiao !== 0 || tiao2 !== 0) && item.url == 'Register') {
        if (tiao !== 0) {
          window.open('https://passport.17173.com/register', '_blank')
        } else if (tiao2 !== 0) {
          window.open('http://www.9i173.com/reg?p=ea574708ce10f0745a87dd86500ae83a ', '_blank')
        }

        return
      }
      if ((tiao !== 0 || tiao2 !== 0) && item.url == 'DownLoad') {
        if (tiao !== 0) {

          window.open('https://www.17173.com', '_blank')

        } else if (tiao2 !== 0) {
          window.open('http://wx.9i173.com/', '_blank')
        }
        return
      }

      if (item.name == '商城专区') {
        if ((tiao !== 0 || tiao2 !== 0) && item.name == '商城专区') {
          if (tiao !== 0) {
            // console.log(item.url+'?s=17173');
            window.open(item.url+'?s=17173', '_blank')

          } else if (tiao2 !== 0) {
            // console.log(item.url+'?s=9i173');
            window.open(item.url+'?s=9i173', '_blank')
          }
          return
        } else {
          // console.log(item.url);
          window.open(item.url, '_blank');    // 商城
        }

        
      } else {
        if (item.url == 'Recharge') {
          this.$router.push('/recharge')
          this.navActive = ind
        } else if (item.url == 'Register') {
          this.$router.push('/register')
        } else if (item.url == 'DownLoad') {
          this.$router.push('/downLoad')
        }
        else {
          document.documentElement.scrollTop = 0
          this.$router.push(item.url)
          this.navActive = ind
        }
      }
    },
  },
}
</script>
<style scoped lang="less">
.headerWrap {
  margin-bottom: 10px;
  position: sticky;
  top: 0;
  z-index: 999;

  .header {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 90px;
    color: #333333FF;
    // color: #fff;
    // background: rgba(116, 81, 255, 0.8);
    background: #FFFFFF80;

    .logoWrap {
      margin-right: 5px;

      img {
        height: 90px;
      }
    }

    .age {
      img {
        height: 90px;
      }
    }

    .navWrap {
      padding-left: 10px;

      .navLi {
        margin: 0 35px;
        display: inline-block;
        height: 50px;
        line-height: 50px;
        text-align: center;
        font-size: 24px;
        font-weight: 700;
        cursor: pointer;
      }

      .active {
        border-bottom: 4px solid #ffffff;
      }
    }
  }

  .headerScr {
    // background: rgba(116, 81, 255, 1);
    background: #FFFFFF80;

  }
}
</style>
