<template>
  <div class="footerWrap w100">
    <div class="footer">
      <div class="footerT">
        <img :src="footer1" alt="">
        <img class="logo" :src="logoImg" alt="">
        <img :src="footer3" alt="">
      </div>
      <div class="footerTxt">
        <p>
          <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">沪ICP备20013811号-1</a>&nbsp;
          <img :src="footer6" alt=""><span>网络文化经营许可证：沪网文【2020】5964-409号</span>
        </p>
        <p>
          <img :src="footer4" alt=""><a href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011002005726" target="_blank">沪公网安备31011002005726号</a>&nbsp;
          <span>增值电信业务经营许可证: 沪B2-20200847 </span>
        </p>
        <p>上海乾田通亨科技发展有限公司</p>
        <p>抵制不良游戏 拒绝盗版游戏 注意自我保护 谨防受骗上当 适度游戏益脑 沉迷游戏伤身 合理安排时间 享受健康生活</p>
        <p>本游戏适合18岁以上用户，不含暴力、恐怖、残酷、色情等妨害未成年人身心健康的内容，属于绿色健康产品</p>
      </div>
      <div class="footerB">
        <img :src="footer5" alt="">
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'App',
    data(){
      return {
        footer1:require('@/assets/imgs/foot/footer1.png'),
        logoImg:require('@/assets/imgs/head/logo.png'),
        footer3:require('@/assets/imgs/foot/footer3.png'),
        footer4:require('@/assets/imgs/foot/footer4.png'),
        footer5:require('@/assets/imgs/foot/footer5.png'),
        footer6:require('@/assets/imgs/foot/footer6.png'),
      }
    },
    methods:{
    },
  }
</script>
<style scoped lang="less">
  .footerWrap{
    padding:20px 0 10px;
    background: #17182A;
    .footerT{
      margin:10px 0;
      img{
        margin:0 50px;
        height: 60px;
        vertical-align: middle;
      }
      .logo{
        height: 80px;
      }
    }
    .footerB{
      margin-top:10px;
    }
    .footer{
      margin:0 auto;
      text-align: center;
      .footerTxt{
        p{
          color: #fff;
          text-align: center;
          line-height: 26px;
          font-size: 18px;
          img{
            vertical-align: middle;
          }

          a{
            display: inline-block;
            color: #fff;
            margin:0 5px;
            line-height: 20px;
            vertical-align: middle;
            &:hover{
              cursor: pointer;
              text-decoration: underline;
            }
          }
          span{
            display: inline-block;
            vertical-align: middle;
          }
        }
      }
    }
  }

</style>



