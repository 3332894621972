<template>
  <div class="mainWrap">
    <div class="mainBar" :style="{
      backgroundImage: 'url(' + (winW > 1920 ? bannerBg2x : bannerBg) + ')',
    }">

      <headerWX></headerWX>
      <router-view></router-view>
      <footerWX></footerWX>
      <div class="suspensionWrap" v-if="suspensionShow">
        <div class="suspension">
          <img class="closed" @click="suspensionShow = false" :src="require('@/assets/imgs/public/close.png')" alt="" />
          <img class="pic" :src="require('@/assets/imgs/public/suspension.png')" alt="" />
        </div>
      </div>

      <!-- H5遮罩层 活动 -->
      <div id="mask" ref="m" v-if="mask.maskShow" v-cloak>
        <div class="mask_img" ref="maskimg" v-for="(item, index) in close" :key="index" v-show="item.type">
          <a class="mask_img_a_two" :href="item.links" target="_blank"></a>
          <!-- <button
            :class="item.id == 1 ? 'mask_one' : 'mask_two'"
            @click="maskAB(item.id)"
          >
            X
          </button> -->
          <button class="mask_one" @click="maskAB(item, index)" v-if="yanchi">
            <img src="@/assets/closeBtn.png" alt="">
          </button>
          <img :src="item.url" />
          <div class="input_check_one" v-if="yanchi">
            <input type="checkbox" v-model="item.checkFlag" /> 今日不再提示
          </div>

          <!-- <input
            v-if="item.id == 2"
            class="input_check_two"
            type="checkbox"
            v-model="mask.checkFlagtwo"
          /> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import headerWX from "@/components/Header";
import footerWX from "@/components/Footer";
import axios from "axios";
export default {
  name: "index",
  components: {
    headerWX,
    footerWX,
  },
  data() {
    return {
      bannerBg: require("@/assets/imgs/public/bannerBg.png"),
      bannerBg2x: require("@/assets/imgs/public/bannerBg2x.png"),
      // bannerBg4: require("@/assets/imgs/public/bannerBg4.png"),
      winW: 1200,
      suspensionShow: true,
      yanchi: false,
      mask: {
        maskimg: [],
        maskShow: false,
        maskone: true,
        masktuo: false,
        checkFlagone: false,
        checkFlagtwo: false,
      },
      LCnoe: {
        type: false,
        tiem: "",
      },
      LCwto: {
        type: false,
        tiem: "",
      },
      close: []
    };
  },
  created() {

    this.getActivities();
  },
  mounted() {
  },

  methods: {
    winResize() {
      this.winW = document.documentElement.clientWidth;
    },
    async getActivities() {
     
      const that = this;
      // console.log(Date.parse(new Date(new Date(new Date().toLocaleDateString()).getTime()+24*60*60*1000-1)), 'll', Date.parse(new Date()));
      // this.LCnoe = JSON.parse(localStorage.getItem("checkFlagone"))
      //   ? JSON.parse(localStorage.getItem("checkFlagone"))
      //   : { type: "", tiem: "" };
      // this.LCwto = JSON.parse(localStorage.getItem("checkFlagtwo"))
      //   ? JSON.parse(localStorage.getItem("checkFlagtwo"))
      //   : { type: "", tiem: "" };
      // console.log(this.LCnoe.type && this.LCwto.type ||  this.LCnoe.tiem ==''?false: Date.parse(new Date()) > this.LCnoe.tiem || this.LCwto.tiem ==''?false: Date.parse(new Date()) > this.LCwto.tiem )
      if (this.$router.history.current.fullPath == "/") {
        const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
        // if (
        //   this.LCnoe.tiem == "" ? false : Date.parse(new Date()) > this.LCnoe.tiem
        // ) {
        //   this.LCnoe.type = false;
        // }
        // if (
        //   this.LCwto.tiem == "" ? false : Date.parse(new Date()) > this.LCwto.tiem
        // ) {
        //   this.LCwto.type = false;
        // }
        // if (this.LCnoe.type && this.LCwto.type) {
        //   this.mask.maskShow = false;
        // } else {
        //   if (this.LCnoe.type) {
        //     this.mask.maskone = false;
        //     this.mask.masktuo = true;
        //   }
        //   if (this.LCwto.type == true) {
        //     this.mask.maskone = true;
        //     this.mask.masktuo = false;
        //   }

        axios({
          method: "get",
          url: "/common/getWebImgList",
          xhrFields: {
            withCredentials: true,
          },
        })
          .then((res) => {
            // this.mask.maskimg = res.data.data.list;
            // console.log(this.mask.maskimg, res.data, 'list');

            for (let index = 0; index < res.data.data.list.length; index++) {
              if (localStorage.getItem(`checkFlag${index + 1}`)) {
                const outTime = JSON.parse(localStorage.getItem(`checkFlag${index + 1}`)).tiem
                const nowTime = Date.parse(new Date())
                if (nowTime > outTime) {
                  that.close.push({
                    type: false,
                    id: index + 1,
                    url: res.data.data.list[index].imgUrl,
                    links: res.data.data.list[index].links,
                    checkFlag: false
                  })

                }
                // console.log(Date.parse(new Date()),JSON.parse(localStorage.getItem(`checkFlag${index + 1}`)).tiem , 'local');
              } else {
                that.close.push({
                  type: false,
                  id: index + 1,
                  url: res.data.data.list[index].imgUrl,
                  links: res.data.data.list[index].links,
                  checkFlag: false
                })
              }


            }
            // 是否启用遮罩层
            if (that.close.length == 0) {
              console.log("遮罩层未启用");
              this.mask.maskShow = false;
              loading.close();

              return
            } else {
              that.mask.maskShow = true;

              this.$nextTick(function () {
                let timer = setInterval(() => {
                  if (that.$refs.maskimg[0].clientWidth > 0) {
                    loading.close();
                    that.yanchi = true;
                    clearInterval(timer)
                  }
                })
              })
              that.close[0].type = true

            }

          })
          .catch((err) => {
            console.log(err);
          });
        // }
      } else {
        console.log('不是首页');
        this.mask.maskShow = false;
      }

    },
    qiyong() {
      const that = this;

    },
    maskAB(item, index) {
      // this.maskShow = false
      console.log(this.close, 'type');
      if (item.checkFlag == true) {
        localStorage.setItem(
          `checkFlag${item.id}`,
          JSON.stringify({
            id: item.id,
            tiem: Date.parse(
              new Date(
                new Date(new Date().toLocaleDateString()).getTime() +
                24 * 60 * 60 * 1000 -
                1
              )
            ),
          })
        );
        // this.close[item.id].type = false
      }


      if (item.id == this.close[this.close.length - 1].id) {
        this.mask.maskShow = false;

        return
      } else {
        // console.log(this.close, 'type');
        this.close[index + 1].type = true;
        item.type = !item.type

      }




    },
  },
};
</script>

<style lang="less" scoped>
.mainWrap {
  min-width: 1200px;
  min-height: 100vh;
  background: linear-gradient(180deg, #300055 0%, #17182a 100%);
  // background: #58182B;
  // background-image: url('../../assets/imgs/public/bannerBg4.png');

  .mainBar {
    color: #333333;
    background-position: top center;
    background-repeat: no-repeat;
    min-height: 100vh;

    .suspensionWrap {
      position: fixed;
      right: 10px;
      top: 60%;
      z-index: 10;

      .suspension {
        position: relative;
        z-index: 0;
      }

      .closed {
        position: absolute;
        right: 0;
        top: 0;
        z-index: 1;
        width: 50px;
        cursor: pointer;
      }

      .pic {
        width: 300px;
      }
    }

    [v-cloak] {
      display: none !important
    }

    #mask {
      width: 100vw;
      height: 100vh;
      // opacity: 0.6;
      background-color: rgba(0, 0, 0, 0.8);
      bottom: 0;
      left: 0;
      position: fixed;
      z-index: 999;

      .mask_img {
        opacity: 1;
        width: auto;
        height: auto;
        z-index: 9999;
        position: fixed;
        // position: relative;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        img {
          width: 100%;
          height: 100%;
        }

        .mask_one {
          width: 44px;
          height: 35px;
          // font-size: 45px;
          background-color: #ff000000;
          border: none;
          color: #fff;
          z-index: 9999;
          position: absolute;
          // font-weight: 900;
          top: 30px;
          right: -40px;
          // opacity: 0;
        }

        .mask_two {
          width: 50px;
          height: 50px;
          background-color: red;
          z-index: 9999;
          position: absolute;
          top: 80px;
          right: 3px;
          // opacity: 0;
        }

        .input_check_one {
          width: 25%;
          height: 20px;
          z-index: 9999;
          position: absolute;
          bottom: -10px;
          left: 45%;
          font-size: 14px;
          color: #fff;
        }

        .input_check_two {
          width: 20px;
          height: 20px;
          z-index: 9999;
          position: absolute;
          bottom: 7px;
          left: 40%;
        }

        .mask_img_a_one {
          width: 75rem;
          height: 38rem;
          z-index: 9999;
          position: fixed;
          top: 57%;
          left: 50%;
          transform: translate(-50%, -50%);
        }

        .mask_img_a_two {
          width: 90%;
          height: 80%;
          z-index: 9999;
          position: fixed;
          top: 51%;
          left: 50%;
          transform: translate(-50%, -50%);
        }

        // right: 500px;
      }
    }
  }
}
</style>
